var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import NumberRange from "~/components/common/number-range.vue";
import DelCaseBatchNumberForever from "~/components/case-manage/case-adjustment/del-case-batch-number-forever.vue";
import { SortService } from "~/utils/sort.service";
import { HistoryCasesService } from "~/services/business-service/history-cases.service";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
var DeleteCasePool = /** @class */ (function (_super) {
    __extends(DeleteCasePool, _super);
    function DeleteCasePool() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.searchModel = {
            principalId: '',
            personalName: "",
            certificateNo: "",
            batchNumber: "",
            caseNumber: "",
            caseType: '',
            city: "",
            delegationDate: [],
            endCaseDate: [],
            stage: "",
            phone: "",
            cardNo: "",
            address: "",
            employerName: "",
            caseNumbers: "",
            certificateNos: "",
            phones: "",
            leftAmt: {
                min: '',
                max: ''
            },
            overdueAmtTotal: {
                min: '',
                max: ''
            },
            overduePeriods: {
                min: '',
                max: ''
            },
            overdueDays: {
                min: '',
                max: ''
            },
            deleteCaseDate: []
        };
        _this.dataSet = [];
        _this.selectionList = [];
        _this.exportData = {};
        _this.dialog = {
            batchNumberDel: false
        };
        _this.rules = {
            certificateNo: [{ min: 6, message: '请输入至少6位的身份证号', trigger: 'blur' }],
            phone: [{ min: 4, message: '请输入至少4位的手机号', trigger: 'blur' }],
            cardNo: [{ min: 6, message: '请输入至少6位的卡号', trigger: 'blur' }],
            account: [{ min: 6, message: '请输入至少6位的账号', trigger: 'blur' }]
        };
        return _this;
    }
    DeleteCasePool.prototype.removeEmptyProperties = function (obj) {
        return Object.fromEntries(Object.entries(obj)
            .filter(function (_a) {
            var _ = _a[0], value = _a[1];
            return value !== null &&
                value !== undefined &&
                value !== "" &&
                (Array.isArray(value) ? value.length > 0 : true) &&
                (typeof value === "object" && !Array.isArray(value) ? Object.keys(value).length > 0 : true);
        }));
    };
    DeleteCasePool.prototype.refreshData = function () {
        var _this = this;
        this.historyCasesService.queryDeleteCases(this.removeEmptyProperties(this.searchModel), this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.dataSet = data;
        });
    };
    DeleteCasePool.prototype.mounted = function () {
        this.refreshData();
    };
    DeleteCasePool.prototype.handleResetForm = function () {
        this.searchModel.principalId = '';
        this.searchModel.overdueAmtTotal = {};
        this.searchModel.leftAmt = {};
        this.searchModel.overduePeriods = {};
        this.searchModel.overdueDays = {};
    };
    DeleteCasePool.prototype.deleteCases = function () {
        var _this = this;
        if (!this.selectionList.length) {
            this.$message("请选择要删除的案件！");
            return;
        }
        var idList = this.selectionList.map(function (v) { return v.id; });
        this.$confirm("确定删除已选择的案件吗？", "提示", { type: "warning" })
            .then(function () {
            _this.historyCasesService.deleteCasesPermanent(idList, '').subscribe(function (data) {
                _this.$message.success("案件删除成功！");
                _this.refreshData();
            }, function (_a) {
                var msg = _a.msg;
            });
        });
    };
    DeleteCasePool.prototype.onBatchNumberDelOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs["del-case-batch-number-forever"];
            form.refreshData();
        });
    };
    __decorate([
        Dependencies(PageService)
    ], DeleteCasePool.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], DeleteCasePool.prototype, "sortService", void 0);
    __decorate([
        Dependencies(HistoryCasesService)
    ], DeleteCasePool.prototype, "historyCasesService", void 0);
    DeleteCasePool = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                NumberRange: NumberRange,
                PrincipalBatch: PrincipalBatch,
                DelCaseBatchNumberForever: DelCaseBatchNumberForever
            }
        })
    ], DeleteCasePool);
    return DeleteCasePool;
}(Vue));
export default DeleteCasePool;
